import React from 'react'
import Layout from '../layouts'
import map from '../images/map.jpg'
// import pdf from '../images/2019_Colorado_CannaTimes_Media_Kit.pdf'

class AdvertisePage extends React.Component {
  componentDidMount() {
    if (window) {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = `https://forms.webriq.com/js/initForms`
      const headScript = document.getElementsByTagName('script')[0]
      headScript.parentNode.insertBefore(script, headScript)
    }
  }
  render() {
    return (
      <Layout>
        <div className="page-heading inner-page">
          <div className="container">
            <div className="text-center">
              <h1>Advertise With Us</h1>
            </div>
          </div>
          <div className="page-heading-bg" />
        </div>
        <div className="page-content">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <p>
                  Colorado CannaTimes is an upscale lifestyle magazine
                  highlighting the industry's best events, food, and culture as
                  well as coverting the latest in politics, education, and the
                  law. We'll cover Denver, Colorado Springs, Manitou Springs,
                  Pueblo, as well as Southern and Western parts of Colorado. We
                  will print 75,000 magazines in Fall & Spring and distribute
                  throughout the Front Range. Our distirubtion company of record
                  is Certified Folder.
                </p>
              </div>
              <div className="col-md-4">
                <h3>Distribution Area</h3>
                <ul>
                  <li>Breckenridge</li>
                  <li>Buena Vista</li>
                  <li>Cañon City</li>
                  <li>Castle Rock</li>
                  <li>Colorado Springs</li>
                  <li>Copper</li>
                  <li>Cripple Creek</li>
                  <li>Denver</li>
                  <li>Glendale</li>
                  <li>Manitou Springs</li>
                  <li>Monument</li>
                  <li>Pueblo</li>
                  <li>Salida</li>
                  <li>Trinidad</li>
                  <li>Vail</li>
                  <li>Woodland Park</li>
                </ul>
              </div>
              <div className="col-md-8">
                <h3>
                  <a
                    className="pb-5 download-link"
                    href="https://res.cloudinary.com/dtguzkxww/image/upload/v1552027212/2019_Colorado_CannaTimes_Media_Kit.pdf"
                    style={{ color: '#6caf0c' }}
                  >
                    Download our Colorado CannaTimes Media Kit PDF
                  </a>
                </h3>
                <img
                  src={map}
                  alt="Distirbution Area Map"
                  className="pr-5 mr-5"
                />
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-md-12">
                <div className="form-group contact-us">
                  <h1>Contact Us</h1>
                  <i className="fas fa-phone" />
                  &nbsp;&nbsp;
                  <label>Tel:&nbsp;</label>
                  <a href="tel:719.238.6211">719.238.6211</a>
                </div>
                <form
                  action="/thank-you"
                  method="post"
                  data-form-id="5c6e6ef98ba9a30a93a2a06a"
                  webriq="true"
                >
                  <div className="form-group">
                    <label>Your Name:</label>
                    <input
                      className="form-control"
                      type="text"
                      name="name"
                      required=""
                    />
                  </div>
                  <div className="form-group">
                    <label>Your Email: *</label>
                    <input
                      className="form-control"
                      type="text"
                      name="email"
                      required=""
                    />
                  </div>
                  <div className="form-group">
                    <label>Your Phone Number with Area Code</label>
                    <input
                      className="form-control"
                      type="text"
                      name="phone-number"
                      required=""
                    />
                  </div>
                  <div className="form-group">
                    <label>Your Message</label>
                    <textarea
                      className="form-control"
                      rows="10"
                      name="comments"
                    />
                  </div>
                  <div className="webriq-recaptcha mb-3" />
                  <div className="form-group">
                    <button className="btn btn-primary btn-block" type="submit">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default AdvertisePage
